import React from 'react'
import Router from './routes/RoutesConfig'

function App() {
	return (
		<Router />
	)
}

export default App
